<template xmlns="http://www.w3.org/1999/html">
  <div class="main LoginWin">
    <a-form
      id="formLogin"
      ref="formLogin"
      :form="form"
      class="user-layout-login"
      @submit="handleSubmit"
    >
      <a-form-item>
        <a-input
          v-decorator="[
            'username',
            {rules: [{ required: true, message: '请输入用户名'},{ min: 4,max:30, message: '请输入6-30位用户名' }, { validator: handleUsernameOrEmail }], validateTrigger: 'change'}
          ]"
          placeholder="请输入账号"
          size="large"
          type="text"
        >
          <a-icon slot="prefix" :style="{ color: 'rgba(0,0,0,.25)' }" type="user"/>
        </a-input>
      </a-form-item>

      <a-form-item>
        <a-input-password
          v-decorator="[
            'password',
            {rules: [{ required: true, message: '请输入密码' },{ min: 4,max:30, message: '请输入6-30位字符' }], validateTrigger: 'blur'}
          ]"
          placeholder="密码:"
          size="large"
        >
          <a-icon slot="prefix" :style="{ color: 'rgba(0,0,0,.25)' }" type="lock"/>
        </a-input-password>
      </a-form-item>
      <a-form-item>
        <a-checkbox v-decorator="['isRemember', { valuePropName: 'checked' }]">自动登录</a-checkbox>
      </a-form-item>
      <a-form-item style="margin-top:24px">
        <a-button
          :disabled="state.loginBtn"
          :loading="state.loginBtn"
          class="login-button"
          htmlType="submit"
          size="large"
          type="primary"
        >确定
        </a-button>
      </a-form-item>
    </a-form>

    <two-step-captcha
      v-if="requiredTwoStepCaptcha"
      :visible="stepCaptchaVisible"
      @cancel="stepCaptchaCancel"
      @success="stepCaptchaSuccess"
    ></two-step-captcha>
  </div>
</template>

<script>
import md5 from 'md5'
import { mapActions } from 'vuex'
import { getCaptcha } from '@/api/login'

export default {
  data () {
    return {
      captcha: '',
      loginBtn: false,
      isRemember: 0,
      isLoginError: false,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,
      form: this.$form.createForm(this),
      state: {
        time: 60,
        loginBtn: false
      }
    }
  },
  created () {
    this.getClickCaptcha()
  },
  methods: {
    ...mapActions(['Login', 'Logout']),
    // handler
    handleUsernameOrEmail (rule, value, callback) {
      const { state } = this
      const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
      if (regex.test(value)) {
        state.loginType = 0
      } else {
        state.loginType = 1
      }
      callback()
    },
    handleSubmit (e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state,
        Login
      } = this

      state.loginBtn = true

      const validateFieldsKey = ['username', 'password', 'isRemember']

      validateFields(validateFieldsKey, { force: true }, (err, values) => {
        if (!err) {
          const loginParams = { ...values }
          delete loginParams.username
          loginParams.username = values.username
          loginParams.password = md5(values.password)
          if (values.isRemember === true) {
            loginParams.isRemember = 1
          } else {
            loginParams.isRemember = 0
          }

          Login(loginParams)
            .then((res) => this.loginSuccess(res))
            .catch(err => this.requestFailed(err))
            .finally(() => {
              state.loginBtn = false
            })
        } else {
          setTimeout(() => {
            state.loginBtn = false
          }, 600)
        }
      })
    },
    getClickCaptcha () {
      getCaptcha({}).then(res => {
        var blob = res
        this.captcha = window.URL.createObjectURL(blob)
      })
    },
    stepCaptchaSuccess () {
      this.loginSuccess()
    },
    stepCaptchaCancel () {
      this.Logout().then(() => {
        this.loginBtn = false
        this.stepCaptchaVisible = false
      })
    },
    loginSuccess (res) {
      this.$router.push({ path: '/' })
    },
    requestFailed (err) {
      this.isLoginError = true
      this.$notification['error']({
        message: '错误',
        description: err.message,
        duration: 4
      })
      this.getClickCaptcha()
    }
  }
}
</script>

<style lang="less" scoped>
.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #1890ff;
      }
    }

    .register {
      float: right;
    }
  }
}
</style>
